import React from "react"
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { use100vh } from "react-div-100vh"
import { SiteMetaConfig } from "../config/config-data"
import "../styles/global.css"


const NotFoundPage = ({ location }) => (
  
  <>
    <GatsbySeo
      title={`404: Page Not found | ${SiteMetaConfig.site_name}`}
      description={SiteMetaConfig.site_description}
      canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
      openGraph={{
        type: "website",
        url: `${SiteMetaConfig.site_url}${location.pathname}`,
        title: `404: Page Not found | ${SiteMetaConfig.site_name}`,
        description: SiteMetaConfig.site_description,
        images: [
          { url: SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={true}
      nofollow={true}
    />
    <div id="404_1662795864916" style={{minHeight: use100vh(), display: "grid", alignItems: "center"}}>
      <div id="404_1662795865789" className="Card Card_Container">
        <div id="404_1662795866977" className="Sub_Heading_Text">OOOPS....THIS IS AWKWARD</div>
        <div id="404_1662795868693" className="Normal_Text">THE PAGE YOU ARE LOOKING FOR IS NOT AVAILABLE. MAYBE ANYONE FROM OUR DEVELOPMENT TEAM REMOVED THE PAGE OR MOVED IT TO A NEW ADDRESS. OUR DEVELOPMENT TEAM MUST BE PUNISHED FOR THIS UNACCEPTABLE FAILURE.</div><div id="404_1662795915835" className="Extra_Spacing" />
        <div id="404_1662795869788" className="Normal_Text">IN THE MEANTIME FEEL FREE TO HAVE A LOOK AT OUR WEBSITE, YOU MIGHT FIND IT.</div><div id="404_1662795905437" className="Extra_Spacing" />
        <Link id="404_1662795870780" to="/"><button id="404_1662795890844">Click Here</button></Link>
      </div>
    </div>
  </>
)

export default NotFoundPage
